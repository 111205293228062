<template>
  <b-card title="Dung lượng lưu trữ">
    <storage-chart-component
      ref="storageChart"
      :height="275"
      :data="pieChart.data"
      :options="pieChart.options"
      class="mb-3"
    />
    <div
      v-for="(stock,index) in stockData"
      :key="stock.device"
      :class="index < stockData.length-1 ? 'mb-1':''"
      class="d-flex justify-content-between"
    >
      <div class="d-flex align-items-center">
        <feather-icon
          :icon="stock.symbol"
          size="16"
          :class="stock.color"
        />
        <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
        <span>- {{ stock.percentage }}</span>
      </div>
    </div>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import StorageChartComponent from '@/views/maintenance/chart/StorageChartComponent.vue'
import { $themeColors } from '@themeConfig'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { STORAGE_MAINTENANCE_BACKUP } from '@/const/status'

export const Attr = Object.freeze({
  store: 'maintenanceBackup',
})
export default {
  components: {
    StorageChartComponent,
    BCard,
  },
  data() {
    return {
      isLoading: false,
      storageMaintenanceBackup: STORAGE_MAINTENANCE_BACKUP,
      percentUsed: null,
      dataChart: [],
      pieChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: { display: false },
          tooltips: {
            callbacks: {
              label(tooltipItem, data) {
                const label = data.labels[tooltipItem.index] || ''
                const value = data.datasets[0].data[tooltipItem.index]
                const output = ` ${label} : ${value} %`
                return output
              },
            },
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            // eslint-disable-next-line no-use-before-define
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
        },
        data: {
          labels: ['Đã sử dụng', 'Còn trống'],
          datasets: [
            {
              data: [],
              // eslint-disable-next-line no-use-before-define
              backgroundColor: [$themeColors.primary, chartColors.successColorShade],
              borderWidth: 0,
              pointStyle: 'rectRounded',
            },
          ],
        },
      },
      stockData: [
        {
          device: 'Đã sử dụng',
          symbol: 'SaveIcon',
          color: 'text-primary',
          percentage: null,
        },
        {
          device: 'Còn trống',
          symbol: 'ServerIcon',
          color: 'text-success',
          percentage: null,
        },
        {
          device: 'Tổng',
          symbol: 'HardDriveIcon',
          color: 'text-success',
          percentage: null,
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      countStorageUsed: `${Attr.store}/countStorageUsed`,
    }),
  },
  async created() {
    await this.getDataFromStore()
  },
  methods: {
    ...mapActions({
      getStorageChart: `${Attr.store}/getStorageChart`,
    }),
    async getDataFromStore() {
      this.isLoading = true
      try {
        await this.getStorageChart()
        this.dataChart = []
        this.percentUsed = ((this.countStorageUsed / this.storageMaintenanceBackup) * 100).toFixed(2)
        this.dataChart.push(this.percentUsed)
        this.dataChart.push((100 - this.percentUsed).toFixed(2))
        this.pieChart.data.datasets[0].data = this.dataChart
        this.stockData[0].percentage = `${this.countStorageUsed} MB - ${this.percentUsed} %`
        this.stockData[1].percentage = `${this.storageMaintenanceBackup - this.countStorageUsed} MB - ${(100 - this.percentUsed).toFixed(2)} %`
        this.stockData[2].percentage = `${this.storageMaintenanceBackup} MB`
        this.$refs.storageChart.renderChart(this.pieChart.data, this.pieChart.options)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
  },
}

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}
</script>
