<template>
  <validation-observer
    ref="inputPasswordFormRef"
    #default="{invalid}"
  >
    <b-modal
      id="inputPasswordModal"
      centered
      title="Nhập mật khẩu phục hồi dữ liệu"
      :no-close-on-backdrop="true"
      @hide="onHide"
    >
      <b-form>
        <b-form-group label-for="password">
          <template v-slot:label>
            Mật khẩu <span class="text-danger">*</span>
          </template>
          <validation-provider
            #default="{ errors }"
            name="Mật khẩu"
            rules="required"
          >
            <b-form-input
              id="password"
              v-model="password"
              type="password"
              name="password"
              :state="getElementState(errors)"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
            :disabled="invalid"
            @click="onSave"
          >
            <span class="text-right">
              <feather-icon icon="CheckIcon" /> Xác nhận
            </span>
          </b-button>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-secondary"
            @click="$bvModal.hide('inputPasswordModal')"
          >
            <span class="text-right">
              <feather-icon icon="XIcon" /> Hủy
            </span>
          </b-button>
        </div>
      </template>
    </b-modal>
  </validation-observer>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'InputPassword',
  directives: {
    Ripple,
  },
  components: {
    BFormInput,
    BModal,
    BButton,
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormGroup,
  },
  data() {
    return {
      password: '',
    }
  },
  methods: {
    onHide() {
      this.$refs.inputPasswordFormRef.reset()
      this.password = ''
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
    onSave() {
      this.$emit('succeed', this.password)
    },
  },
}
</script>
